import ApiClient from "./client";
import * as utils from "./utils";
import countries from "./countries";
import Axios from "axios";
let baseURL;

const hashes = window.location.search
  .slice(window.location.search.indexOf("?") + 1)
  .split("&");
let params = {};

if (hashes.length != 0) {
  hashes.forEach((hash) => {
    const [key, val] = hash.split("=");

    params[key] = decodeURIComponent(val);
  });
  sessionStorage.setItem("params", params);
} else {
  try {
    params = JSON.parse(sessionStorage.getItem("params"));
  } catch (error) {
    sessionStorage.removeItem("params");
  }
}

if (params.version) {
  baseURL = `https://${params.version.replace(".", "")}.appspot.com`;
} else if (params.subdomain) {
  baseURL = `https://${params.subdomain.replace(".", "")}.nextinline.io`;
} else if (
  params.custom &&
  window.location.host.includes("http://localhost:4001")
) {
  baseURL = params.custom;
} else {
  // baseURL = "https://shark.nextinline.io"
  baseURL = window.location.host.includes("localhost")
    ? "http://localhost:4000"
    : window.location.origin
        .replace("otter", "shark")
        .replace("scheduling", "shark");
}
// baseURL =`https://20241108t114622-dot-ocean-246520.appspot.com/`
// baseURL = 'https://20240108t210724-dot-ocean-246520.uk.r.appspot.com'
// baseURL = 'https://20240619t144417-dot-ocean-246520.uk.r.appspot.com'
// baseURL = 'https://20240619t171645-dot-ocean-246520.uk.r.appspot.com'

export const apiConfig = {
  baseURL,
  version: params.version,
};

const axiosClient = Axios.create(apiConfig);

const apiClient = new ApiClient(apiConfig);
window.otterApiClient = apiClient;

export { apiClient, axiosClient, utils, countries };
