const workerNames = [
  {
    value: "ClassificationsWorker",
    label: "Classifications",
  },
  {
    value: "BreedsWorker",
    label: "Breeds",
  },
  {
    value: "GendersWorker",
    label: "Genders",
  },
  {
    value: "AppointmentTypesWorker",
    label: "Appointment Types",
  },
  {
    value: "AppointmentStatusesWorker",
    label: "Appointments Statuses",
  },
  {
    value: "ProvidersWorker",
    label: "Providers",
  },
  {
    value: "ClientsWorker",
    label: "Clients",
  },
  {
    value: "PatientsWorker",
    label: "Patients",
  },
  {
    value: "AppointmentsWorker",
    label: "Appointments",
  },
  {
    value: "PatientRemindersWorker",
    label: "Patient Reminders",
  },
  {
    value: "SchedulesWorker",
    label: "Schedules",
  },
  {
    value: "ServersWorker",
    label: "Servers",
  },
];

export default workerNames;
